<template>
  <div class="w-full">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <header v-if="step === 1" class="
      mb-4
      flex flex-col-reverse
      items-center
      justify-center
      lg:flex-row lg:justify-between
      gap-3
    ">
      <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div v-if="loading" class="flex justify-center items-center">
          <a-spin>
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
          </a-spin>
        </div>
      </div>
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="
            flex
            absolute
            inset-y-0
            left-0
            items-center
            pl-3
            pointer-events-none
          ">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <a-input-search placeholder="Buscar un usuario en particular" class="rounded-full" style="width: 100%"
            @search="onSearch" v-model="filterSearch" />
        </div>
      </div>
    </header>
    <div v-if="step === 1">
      <div v-if="dataRows.length > 0"
        class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
        <a-table :columns="columns" rowKey="codigo_queja" :data-source="dataRows" :pagination="false"
          :scroll="{ x: '1220px' }" class="text-center">
          <div slot="nombre_cf" class="title-size font-bold text-gray-500">
            Nombre del CF
          </div>
          <div slot="entidad" class="title-size font-bold text-gray-500">
            Entidad
          </div>
          <div slot="codigo_queja" class="title-size font-bold text-gray-500">
            Código interno
          </div>
          <div slot="texto_queja" class="title-size font-bold text-gray-500">
            Contexto
          </div>
          <div slot="actions" class="title-size font-bold text-gray-500">
            Acciones
          </div>

          <div slot="actions" slot-scope="text, record">
            <button class="flex justify-center text-xs mx-auto text-blue h-min" @click="complaintDetails($event, record)">
              <a-icon type="setting" theme="twoTone" two-tone-color="#3366cc" :style="{
                fontSize: '20px',
              }" />
              <b class="my-auto ml-2">Gestionar</b>
            </button>
          </div>
        </a-table>
        <div class="p-4 flex justify-end">
          <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
            show-size-changer size="large" @showSizeChange="onShowSizeChange" />
        </div>
      </div>
      <div class="py-12 justify-center" v-else>
        <b class="text-center text-4xl mx-auto text-gray-400">No se encontraron resultados</b>
      </div>
    </div>
  </div>
</template>

<script>
// #004884
import { mapState } from "vuex";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import Complaint from "@/components/Dashboard/Complaints/Complaint";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";
import DynamicForm from "@/components/DynamicForm/";
const columns = [
  {
    slots: { title: "nombre_cf" },
    dataIndex: "nombre_cf",
    key: "nombre_cf",
    width: 80,
  },
  {
    slots: { title: "entidad" },
    dataIndex: "entidad",
    key: "entidad",
    width: 80,
  },
  {
    slots: { title: "codigo_queja" },
    dataIndex: "codigo_queja",
    key: "codigo_queja",
    width: 80,
  },
  {
    slots: { title: "texto_queja" },
    dataIndex: "texto_queja",
    key: "texto_queja",
    width: 80,
    ellipsis: true,
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 40,
  },
];

export default {
  components: {
    DynamicForm,
    Complaint,
    CustomerLegal,
    CustomerNatural,
    AnnexesManagement
  },
  data() {
    return {
      accordionOpen: true,
      accordionOpen2: false,
      selectedClasification: -1,
      errorCustomer: false,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      filterSearch: "",
      general_references: "!,6,7,8,9",  // 2:attorney, 6:management, 7:answer, 8:fraud, 9:acknowledgment
      step: 1,
      showTab: -1,
      showLimit: false,
      initForm: {},
      loading: false,
      page: 1,
      limit: 10,
      totalPages: 1,
      dataRows: [],
      columns: columns,
      count: 0,
      pageSize: 100,
      complaint: {},
      customer: null,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    this.getData();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    async complaintDetails(checked, record) {

      this.$router.push({'name': 'clasification-demand-detail', params: {id: record.codigo_queja, document_id: record.documento_id}})

    },
    onSearch(value) {
      this.filterSearch = value;
      this.dataRows = [];
      this.getData()
    },
    async getData() {
      this.loading = true;
      let data = {};

      try {
        const search = this.filterSearch == "" ? "" : `&search=${this.filterSearch}`;
        const urlService1 = `/jurisdiccional-servi/complaint-classification?page=${this.page}&page_size=${this.pageSize}${search}`;
        let { error, data: dataPivot } = await this.$api.getResource(
          urlService1
        );
        if (error) {
          console.error(error)
          data = [];
          this.dataRows = data;
          this.count = 0;
        } else {
          console.log(dataPivot)
          data = dataPivot.data;
          this.dataRows = dataPivot.data;
          this.count = dataPivot.count;
          console.log('count', this.count)
        }
      } catch (e) {
        console.log("Err in getData", e);
      }

      this.loading = false;
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page
          },
        })
        .catch((error) => error);
    },
    async onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      await this.getData();
    },
    pagination(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage;
      }
      this.getData();
    },
    currentDate() {
      // Obtener la fecha actual
      const today = new Date();

      // Obtener los componentes de la fecha
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por lo que se suma 1
      const day = String(today.getDate()).padStart(2, "0");

      // Crear la cadena en el formato deseado
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;

      if (files.length > 5) {
        alert("Selecciona máximo 5 archivos.");
        return;
      }

      this.files = files;

      for (const file of files) {

        if (file.size > 1024 * 1024) {
          alert(
            "El archivo " +
            file.name +
            " excede el tamaño máximo permitido (1 MB)."
          );
          return;
        }

        console.log("Archivo seleccionado:", file);
      }
    },
    changeShowTab(index) {
      if (this.showTab === index) {
        this.showTab = -1;
      } else {
        this.showTab = index;
      }
    },
    saveRecommendation() {
      this.step = 1;
    },
    async manage(idClaim) {
      this.loading = true;
      const urlService = `/complaint_detail/${idClaim}`;
      let { data } = await this.$api.getResource(urlService);
      if (data !== undefined) {
        this.initForm = data;
      } else {
        const urlService1 = `/complaint_historical/?codigo_queja=${idClaim}`;
        let { data: data1 } = await this.$api.getResource(urlService1);
        const urlService2 = `/complaint_historical/${idClaim}/?fecha_actualizada=${data1.actualizaciones[data1.actualizaciones.length - 1]
          .fecha_actualizada
          }`;
        let { data: data2 } = await this.$api.getResource(urlService2);
        this.initForm = data2.data;
      }
      this.loading = false;
      this.changeStep(2);
    },
    changeReports(addReport = true, index) {
      if (addReport) {
        this.newRecommendation.reports.push({});
      } else {
        let arrayPivot = [...this.newRecommendation.reports];
        arrayPivot.splice(index, 1);
        this.newRecommendation.reports = arrayPivot;
      }
    },
    changeStep(step = 1) {
      this.step = step;
    },
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    formatStatus(status, defaultClass) {
      const obj = {
        Borrador: "btn-warning-light",
        Formulado: "btn-success-light",
        Finalizado: "btn-success",
        Cerrado: "btn-danger-light",
        Seguimiento: "btn-primary-light",
      };
      return obj[status] + " " + defaultClass;
    },
    formatDate(inputDate) {
      // Convierte la cadena de entrada en un objeto Date
      const date = new Date(inputDate);

      // Obtiene los componentes de la fecha
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Añade cero al principio si es necesario
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      // Calcula la hora en formato 12 horas
      const formattedHours = hours % 12 || 12;

      // Formatea la fecha en el formato deseado en inglés
      const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;

      return formattedDate;
    },
  },
};
</script>

<style scoped>
.search-complaints .ant-table-thead>tr>th {
  text-align: center;
}

.accordion-100>div {
  width: 100%;
}

.selection-padding-10>div>div>div,
.selection-padding-10>div>input {
  padding: 10px !important;
  min-height: 52px;
}

button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.text-primary {
  color: #4558dc;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.rounded {
  border-radius: 50px;
}

div>small {
  color: #7a7a7a;
}

.rounded-border {
  cursor: pointer;
  display: flex;
  padding-top: 2px;
  padding-left: 7px;
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 0.5px solid transparent;
  margin: 0px;
  margin-right: 8px;
}

.limit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
  /* Asegura que el elemento sea bloque para aplicar el ancho máximo */
}

.rounded-border:hover {
  transition: 0.5s;
  color: #4558dc;
}

.rounded-border-active {
  border: 0.5px solid #4558dc;
}

.btn-gray {
  background: #bd9e9e;
  color: black;
}

.btn-primary {
  background: #4558dc;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #14a171;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #4558dc;
}

.btn-success-light {
  background: #e5fbf5;
  color: #14a171;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search>input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search>button {
  border-radius: 10px;
}

.table {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  padding: 10px 0;
  background: #fafafa;
  font-weight: bold;
}

.tableHeader>div>h3 {
  color: #6b6b6b !important;
}

.table>div {
  flex-wrap: wrap;
}

.table>div>div {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar>div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #14a171;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}

.d-none {
  display: none;
}

.containerFileOptional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 20px;
  border-radius: 15px;
}

.containerFileOptional div {
  border-radius: 15px;
  background: rgba(51, 102, 204, var(--tw-bg-opacity));
  margin-bottom: 10px;
  color: white;
  padding: 0.5em 4em;
}

.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}

.cursor {
  cursor: pointer;
}

.contentDropdown {
  position: absolute;
  background: white;
  z-index: 1000;
  border: 1px solid #e7e7e7;
  width: 57px;
}

.contentDropdown>div {
  width: 100% !important;
}
</style>